//
// Gatsby Tinongo 404 page
// Route: Any 404
//

import React from 'react';
import { Link } from 'gatsby';

import AppShell from '../components/app-shell';
import Content from '../components/content';
import Section from '../components/section';
import SEO from '../components/seo';

export default function NotFound() {
  return (
    <>
      <SEO title="404: Seite nicht gefunden." />
      <AppShell>
        <Content>
          <article>
            <header>
              <h1 className="pageTitle">
                404! <br />
                Leider nicht gefunden.
              </h1>
            </header>
            <Section fullWidth>
              <div className="bodyText">
                <Link to="/">Zur Startseite</Link>
              </div>
            </Section>
          </article>
        </Content>
      </AppShell>
    </>
  );
}
